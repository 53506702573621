const data = [
  {
    key: "team",
    breadcrumb: [
      { text: 'Nossa equipe', href: '/#/equipe' },
    ]
  },
  {
    key: "JudicialRecoveries",
    breadcrumb: [
      { text: 'Recuperações Judiciais', href: '/#/casos/recuperacoes' },
    ]
  },
  {
    key: "BankRuptcies",
    breadcrumb: [
      { text: 'Falências', href: '/#/casos/falencias' },
    ]
  },
  {
    key: "JudicialLiquidation",
    breadcrumb: [
      { text: 'Liquidação Judicial', href: '/#/casos/liquidacao-judicial' },
    ]
  },
  {
    key: "CivilInsolvency",
    breadcrumb: [
      { text: 'Insolvência Civil', href: '/#/casos/insolvencia-civil' },
    ]
  },

  {
    key: "News",
    breadcrumb: [
      { text: 'Na mídia', href: '/#/na-midia' },
    ]
  },
  {
    key: "NewsDetails",
    breadcrumb: [
      { text: 'Na mídia', href: '/#/na-midia' },
      { text: 'Detalhes', href: '#' },
    ]
  },
  {
    key: "QuestionsList",
    breadcrumb: [
      { text: 'Dúvidas', href: '/#/duvidas' },
    ]
  },
  {
    key: "Contact",
    breadcrumb: [
      { text: 'Contato', href: '/#/contato' },
    ]
  },
  {
    key: "UserEvents",
    breadcrumb: [
      { text: 'Habilitações e Divergências', href: '/#/habilitacoes-divergencias' },
    ]
  },
  {
    key: "CreateUserEvents",
    breadcrumb: [
      { text: 'Habilitações e Divergências', href: '/#/habilitacoes-divergencias' },
      { text: 'Fomulário', href: '/#/habilitacoes-divergencias/novo' },
    ]
  },
  {
    key: "CreditorsAssembly",
    breadcrumb: [
      { text: 'Assembleia de Credores', href: '/#/assembleia-credores' },
    ]
  },
  {
    key: "DocumentCenterPage",
    breadcrumb: [
      { text: 'Central de Documentos', href: '/#/central-documentos' },
    ]
  },
  {
    key: "AccreditationPage",
    breadcrumb: [
      { text: 'Credenciamento', href: '/#/assembleia-credores/credenciamento' },
    ]
  },
  {
    key: "VotingPage",
    breadcrumb: [
      { text: 'Votacao', href: '/#/assembleia-credores/votacao' },
    ]
  },
  {
    key: "DocumentsTemplates",
    breadcrumb: [
      { text: 'Modelos de Documentos', href: '/#/modelos-documentos' },
    ]
  },
  {
    key: "CasesResume",
    breadcrumb: [
      { text: 'Casos', href: '/#/casos' },
    ]
  },
  {
    key: "CasesDetails",
    breadcrumb: [
      { text: 'Detalhes', href: '#' },
    ]
  },

];


export default [...data];
